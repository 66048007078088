//aboutpage.js
import React from "react";

const AboutPage = () => {
    return (
        <div className="about-page">
        <h1>About Us</h1>
        <p>Welcome to the about page!</p>
        </div>
    );
    }


export default AboutPage;